<template>
  <div class="ewm page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" ref="evalCriteriaGrid"> </esp-dx-data-grid>
  </div>
</template>

<script>
  import { DxSwitch } from 'devextreme-vue/switch';
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
    },
    data() {
      return {
        codes: {
          evalDivisionCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          evalProcessCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            frScheYmd: getPastFromToday(11, 'months'),
            toScheYmd: getPastFromToday(0, 'days'),
          },
          paramsData: {},
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        dataGrid: {
          //keyExpr: 'id',
          refName: 'evalCriteriaGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          focusedRowEnabled: false,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellHoverChanged: false,
            cellPrepared: false,
            checkDataBeforeSaving: false,
            initNewRow: true,
            rowInserted: false,
            rowInserting: false,
            saving: true,
          },
          showActionButtons: {
            copy: false,
            select: true,
            update: false,
            delete: false,
            excel: false,
            customButtons: [
              {
                widget: 'dxButton',
                sortIndex: 20,
                options: {
                  icon: '',
                  text: '복사생성',
                  elementAttr: { class: 'btn_XS default filled' },
                  height: 30,
                  onClick: () => {
                    vm.onDuplicate();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                sortIndex: 30,
                options: {
                  icon: '',
                  text: '추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick: function () {
                    vm.onRegister();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '평가기준명',
              dataField: 'productionName',
              height: 40,
              visible: true,
              allowEditing: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                let aTag = document.createElement('a');
                aTag.innerText = options.value;
                aTag.addEventListener('click', () => {
                  vm.onUpdateData(options.data);
                });
                container.append(aTag);
              },
            },
            {
              multiHeaderNm: '콜건수 가중치',
              columns: [
                {
                  caption: 'IB 건수 가중치',
                  dataField: 'inCntWeight',
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: true,
                  sortOrder: 'none',
                  allowHeaderFiltering: false,
                  allowGrouping: false,
                  calculateDisplayCellValue: rowData => `${rowData.inCntWeight} %`,
                  calculateSortValue: rowData => rowData.inCntWeight,
                },
                {
                  caption: 'OB 건수 가중치',
                  dataField: 'outCntWeight',
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: true,
                  sortOrder: 'none',
                  allowHeaderFiltering: false,
                  allowGrouping: false,
                  calculateDisplayCellValue: rowData => `${rowData.outCntWeight} %`,
                  calculateSortValue: rowData => rowData.outCntWeight,
                },
              ],
            },
            {
              multiHeaderNm: '콜시간 가중치',
              columns: [
                {
                  caption: 'IB 시간 가중치',
                  dataField: 'inTimeWeight',
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  sortOrder: 'none',
                  allowHeaderFiltering: false,
                  allowGrouping: false,
                  calculateDisplayCellValue: rowData => `${rowData.inTimeWeight} %`,
                  calculateSortValue: rowData => rowData.inTimeWeight,
                },
                {
                  caption: 'OB 시간 가중치',
                  dataField: 'outTimeWeight',
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  sortOrder: 'none',
                  allowHeaderFiltering: false,
                  allowGrouping: false,
                  calculateDisplayCellValue: rowData => `${rowData.outTimeWeight} %`,
                  calculateSortValue: rowData => rowData.outTimeWeight,
                },
              ],
            },
            {
              caption: '평가방식',
              dataField: 'evaluationMethod',
              height: 40,
              alignment: 'left',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '배점',
              dataField: 'productionScore',
              width: 90,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '표시여부',
              dataField: 'viewFl',
              width: 110,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              calculateSortValue: data => {
                return this.$_enums.common.stringUsedFlag.values.find(e => data.viewFl === e.value).label;
              },
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.data.viewFl === this.$_enums.common.stringUsedFlag.YES.value,
                    disabled: options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value,
                    onValueChanged: () => vm.onChangeViewFl(options),
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '사용이력',
              dataField: 'useFl',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateSortValue: data => {
                return this.$_enums.common.stringUsedFlag.values.find(e => data.useFl === e.value).label;
              },
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 복사생성 */
      async onDuplicate() {
        const selectedRowsData = this.$refs.evalCriteriaGrid.selectedRowsData;

        if (selectedRowsData.length < 1) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DUPLICATE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const sendData = selectedRowsData.map(x => x.id);

        const payload = {
          actionname: 'EWM_PRODUCTIVITY_EVALCRITERIA_DUPLICATE',
          data: sendData,
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.$refs.evalCriteriaGrid.getGridInstance.refresh();
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 삭제 */
      async onDeleteData() {
        const selectedRowsData = this.$refs.evalCriteriaGrid.selectedRowsData;

        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        const checkUsed = selectedRowsData.findIndex(element => element.useFl !== 'N');

        if (checkUsed > -1) {
          this.$_Msg(`기준이 미사용인 데이터만 삭제 할 수 있습니다.`);
          return;
        }

        if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const sendData = selectedRowsData.map(x => x.id);

        const payload = {
          actionname: 'EWM_PRODUCTIVITY_EVALCRITERIA_DELETE',
          data: sendData,
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
          this.$refs.evalCriteriaGrid.getGridInstance.refresh();
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 등록 페이지 이동 */
      onRegister() {
        this.$store.commit('setDetailParams', {});
        this.$router.push({ path: '/ewm/productivity/implementation/evaluation-criteria/register' });
      },
      /** @description : 표시여부 변경 */
      async onChangeViewFl(options) {
        const viewFl = options.value === 'Y' ? 'N' : 'Y';
        this.$set(options.row.data, 'viewFl', viewFl);
        const payload = {
          actionname: 'EWM_PRODUCTIVITY_EVALCRITERIA_SAVE',
          data: options.row.data,
        };

        const res = await this.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 수정 페이지 이동 */
      onUpdateData(data) {
        this.$store.commit('setDetailParams', data);
        this.$router.push({ path: '/ewm/productivity/implementation/evaluation-criteria/register' });
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load() {
            vm.searchType.paramsData['frScheYmd'] = vm.searchType.customTypes.frScheYmd;
            vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.toScheYmd;

            const payload = {
              actionname: 'EWM_PRODUCTIVITY_EVALCRITERIA_LIST',
              data: vm.searchType.paramsData,
              loading: false,
            };

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              const rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };

              vm.$refs.evalCriteriaGrid.totalCount = rtnData.totalCount;
              return rtnData;
            }
          },
          insert(values) {
            //return false;
          },
          update(key, values) {
            //return false;
          },
          totalCount: opts => {
            //데이터 없는 경우 totalCount 에러 발생하여 추가
            return new Promise((resolve, reject) => {
              resolve(0);
            });
          },
        });
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_eval_division,root_ewm_eval_process');
      },
    },
    mounted() {
      vm = this;
      this.selectDataList();
    },
  };
</script>

<style scoped>
  .page_search_box .inner div {
    display: inline-block;
  }
  .page_search_box .inner > div:not(.box-btn-search) {
    vertical-align: middle;
    margin-right: 10px;
  }
  .page_search_box .spec-type-box {
    display: inline-block;
  }
  .page_search_box .inner .box-btn-search {
    margin-right: 15px;
  }
  .page_search_box #searchTooltip {
    margin-right: 5px;
    font-size: 22px;
    color: #808080;
  }
</style>
